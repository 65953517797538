import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { FormattedDate } from "./formatted-date";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Formatted Date",
	notepad: "https://hackmd.io/HDzZtSbyT7qQpriPRqO85g",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Formatted Date"
			subTitle="Present dates and times in a clear, user-friendly format that reflects their locale."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={FormattedDate} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Consistently display dates and times in a clear, accessible format that aligns with user
					expectations and regional conventions. This ensures clarity and avoids confusion when
					presenting temporal information.
				</Paragraph>
				<Paragraph>Examples:</Paragraph>
				<Ul
					items={[
						"Scheduled reports: 6/25/2024, 02:51 PM",
						"User action log: 7/3/2024 4:28:44 PM",
						"User creation date: 25/03/2024",
						"Last Updated: 2 hours ago",
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Format</Header.H3>
				<Paragraph>Choose the format that best suits the content type and context.</Paragraph>
				<Ul
					items={[
						<>
							<b>Date</b>: Prioritize unambiguous formats (e.g., "March 25, 2024" or "25 March
							2024"). For brevity, consider using abbreviated month names only if space is limited
							and the context is clear. See examples in{" "}
							<a href="https://fancy.siteimprove.com/Writing/grammar-and-mechanics#dates-and-times">
								Grammar and mechanics {">"} Dates
							</a>
							.
						</>,
						<>
							<b>Time</b>: Use the 12-hour clock (AM/PM) for clarity, especially in less formal
							contexts. See examples in{" "}
							<a href="https://fancy.siteimprove.com/Writing/grammar-and-mechanics#times">
								Grammar and mechanics {">"} Times
							</a>
							.
						</>,
					]}
				/>

				<Example fn={FormatsExample} />
				<Header.H3>Locale</Header.H3>
				<Paragraph>
					The date and time display pattern varies with each region of the globe. Therefore, the
					locale parameter allows you to control in which standard the date will be formatted. All
					standard locale codes defined by the ECMAScript Internationalization API are supported.
				</Paragraph>
				<Example fn={LocalesExample} />
				<Header.H3>Timezone Reset</Header.H3>
				<Paragraph>
					By default, dates are displayed in UTC. However, this behavior can be changed to display
					them in your local timezone.
				</Paragraph>
				<Example fn={TimezoneExample} />
				<Header.H3>Empty date</Header.H3>
				<Paragraph>
					When no date or time is available, use a clear placeholder (e.g., "-") and provide an
					appropriate aria-label for screen reader users (e.g., "Date unavailable").
				</Paragraph>
				<Example fn={EmptyDate} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>FormattedDate</Code> when:
					</Paragraph>
					<Ul
						items={[
							"You need to display dates and/or times consistently.",
							"The date/time information is essential to the content or user task.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>FormattedDate</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								In <GatsbyLink to="/lab/components/Tables%20and%20lists/Table">Table</GatsbyLink> /{" "}
								<GatsbyLink to="/lab/components/Tables%20and%20lists/List%20table">
									List table
								</GatsbyLink>
								, displaying the user creation date allows for easy sorting and filtering.{" "}
							</>,
							<>
								Within transaction logs, timestamps provide a detailed audit trail for
								troubleshooting and analysis.{" "}
							</>,
							<>For notifications and alerts, the time received highlights urgency. </>,
							<>In report detail, schedule changed times offer clear scheduling information.</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>FormattedDate</Code> to existing components for visual
								consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								You need interactive date selection. Use{" "}
								<GatsbyLink to="/lab/components/Actions%20and%20controls/Period%20picker">
									Period picker
								</GatsbyLink>{" "}
								instead.{" "}
							</>,
							<>
								The date/time is an example. Use Help text in{" "}
								<GatsbyLink to="/lab/components/Forms%20and%20input/Form%20element%20wrapper">
									Form Element wrapper
								</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between the date/time text and the background.",
							"Ensure that the date/time format is clear and unambiguous for users with visual impairments (consider adding an explicit label if necessary).",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[<>This component comes with built-in accessibility, no extra work required.</>]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								Follow local conventions for date and time formatting (e.g., "March 25, 2024" in
								North America, "25 March 2024" in Europe). Follow the{" "}
								<a href="https://fancy.siteimprove.com/Writing/grammar-and-mechanics#dates-and-times">
									date and time
								</a>{" "}
								guidance .
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const date = new Date("2022-12-31");

const BasicUsage = () => <FormattedDate date={date} locale="en-US" />;
const EmptyDate = () => <FormattedDate date={null} locale="en-US" />;
/** @ignore [visual] */
const LocalesExample = () => (
	<>
		<FormattedDate date={date} locale="da-DK" format="datetime" />
		<br />
		<FormattedDate date={date} locale="en-US" format="datetime" />
		<br />
		<FormattedDate date={date} locale="pt-BR" format="datetime" />
	</>
);
/** @ignore [visual] */
const FormatsExample = () => (
	<>
		<FormattedDate date={date} locale="en-US" format="time" />
		<br />
		<FormattedDate date={date} locale="en-US" format="date" />
		<br />
		<FormattedDate date={date} locale="en-US" format="datetime" />
	</>
);
/** @ignore [visual] */
const TimezoneExample = () => (
	<FormattedDate date={date} locale="en-US" format="datetime" skipTimezoneReset />
);
